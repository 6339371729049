import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";

const ProductUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    productName: '',
    price: '',
  });

  useEffect(() => {
    if (user) {
      getProduct();
    }
    console.log(formData)
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getProduct = () => {
    let req = {
      id: user
    };
    callApi("POST", "productGetId.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          setFormData({
            id: response.id,
            productName: response.productName,
            price: response.price,
          });
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addProduct = () => {
    setLoading(true);
    const trimmedProductName = formData.productName.trim();
    const trimmedProductPrice = formData.price.trim();

    if (trimmedProductName && trimmedProductPrice) {
      let req = {
        productName: trimmedProductName,
        price: trimmedProductPrice,
      };
      callApi("POST", "productInsert.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          console.log("response", response);
          if (response["success"] === true) {
            showSuccess("Success!", "Product created successfully!");
            setTimeout(() => {
              navigate("/layout/product-management");
            }, 1000);
            setLoading(false);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      showError(
        "Failed", "Empty Space Not Allowed");
    }
  }

  const updateProduct = () => {
    setLoading(true);
    let req = {
      id: formData.id,
      productName: formData.productName,
      price: formData.price,
    };
    callApi("POST", "productUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Product updated successfully!");
          setTimeout(() => {
            navigate("/layout/product-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (user) {
      updateProduct();
    } else {
      addProduct();
      console.log(formData)
    }
  };

  return (
    <div style={{ margin: '2% 4% 4%' }}>
      <h5>{user ? 'Edit Product' : 'Add New Product'}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formProductName" style={{ marginTop: '1%' }}>
          <Form.Label>Product Name<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="productName"
            value={formData.productName}
            onChange={handleChange}
            minLength={2}
            maxLength={60}

            required
          />
        </Form.Group>
        <Form.Group controlId="formProductPrice" style={{ marginTop: '1%' }}>
          <Form.Label>Product Price<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="price"
            value={formData.price}
            onKeyPress={(e) => {
              const isNumber = /^[1-9]$/.test(e.key) || (formData.price && /^[0-9]$/.test(e.key));
              if (!isNumber) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              // Allow values only if they don't start with 0 (unless it is empty) and if they are numbers
              if (/^(0|[1-9][0-9]*)$/.test(newValue) || newValue === "") {
                handleChange(e);
              }
            }}
            required
            minLength={1}
            maxLength={5}
          />

        </Form.Group>
        <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', marginTop: '10%' }} type="submit" disabled={loading}>
          {user ? 'Update Product' : 'Add Product'}
        </Button>
      </Form>
    </div>
  );
};

export default ProductUpdate;
