import React, { useEffect, useState } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import { Modal, TagPicker } from 'rsuite';
import RemindIcon from '@rsuite/icons/legacy/Remind';
import EditIcon from '../../assets/edit.png';
import deleteIcon from "../../assets/delete.png";
import './UserManagement.css';
import ExcelPaymentManagement from './ExcelPaymentManagement';
import Pagination from 'rsuite/Pagination';

type Payment = {
  id: number;
  paymentType: string;
  deliveryStatus: string;
  deliveryStatusName: string;
};

type StatusType = {
  id: string;
  status: string;
};

const defaultColumns = [
  { key: 'id', label: 'S.No' },
  { key: 'paymentType', label: 'Payment Method' },
  { key: 'deliveryStatus', label: 'Payment Status' },
  { key: 'actions', label: 'Actions' }
];

const PaymentManagement = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState<Payment[]>([]);
  const [selectedUser, setSelectedUser] = useState<Payment | null>(null);
  const [columnKeys, setColumnKeys] = useState(defaultColumns?.map(col => col.key));
  const [status, setStatus] = useState<StatusType[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof Payment | null, direction: 'asc' | 'desc' } | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(30);

  const start = (activePage - 1) * limit;
  const end = start + limit;
  const paginatedPayment = users.slice(start, end);

  const handlePageChange = (page: any) => {
    setActivePage(page);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setActivePage(1);
  };

  const handleOpen = (user: any) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedUser(null);
    setOpen(false);
  };

  useEffect(() => {
    getDeliveryStatus();
    getPaymentMethod();
  }, [searchTerm]);

  const getPaymentMethod = () => {
    let req = {
      page: '',
      limit: '',
      searchTerm: searchTerm,
    };
    callApi("POST", "paymentMethodList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setUsers(response["list"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleEdit = (user: any) => {
    navigate('/layout/payment-update', { state: { user } });
  };

  const handleDelete = () => {
    if (selectedUser) {
      console.log(`Delete user with ID: ${selectedUser.id}`);
      let req = {
        id: selectedUser.id
      };
      callApi("POST", "paymentMethodDelete.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          if (response["success"] === true) {
            showSuccess("Success", response.message);
            getPaymentMethod();
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    handleClose();
  };

  const getDeliveryStatus = () => {
    callApi("POST", "statusList.php", '')
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setStatus(response.data);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStatusLabel = (id: string) => {
    const statusItem = status.find((item) => item.id === id);
    return statusItem ? statusItem.status : 'Unknown Status';
  };

  const handleAddNew = () => {
    let user = '';
    navigate('/layout/payment-update', { state: { user } });
  };

  const filteredColumns = defaultColumns.filter(column => columnKeys.includes(column.key));

  const handleSort = (key: keyof Payment) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...users].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setUsers(sortedData);
  };

  const getSortIcon = (key: keyof Payment) => {
    if (sortConfig?.key === key) {
      return sortConfig.direction === 'asc' ? '↑' : '↓';
    }
    return '';
  };

  return (
    <div>
      <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Payment Method Management</h5>
      <div className="custom-table">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '98%' }} className='mobile-buttons'>
          <TagPicker
            data={defaultColumns}
            labelKey="label"
            valueKey="key"
            value={columnKeys}
            onChange={setColumnKeys}
            cleanable={false}
            style={{ width: '50%', marginLeft: '4%' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '4%', marginBottom: '2%', width: '22%' }} >
            {users.length === 0 ? (
              "") : (
              <div style={{ marginRight: '1%', display: 'flex', justifyContent: 'flex-end' }}>
                <ExcelPaymentManagement data={users} status='payment-management' />
              </div>
            )}
            <Button onClick={handleAddNew} style={{ marginRight: '4%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', height: '36px' }}>
              Add Payment Method
            </Button>
          </div>
        </div>
        <Form.Control
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '30%', marginLeft: '4%', marginBottom: '20px', marginTop: '20px' }}
        />
        <Table responsive bordered hover style={{ width: '92%', margin: '0% 4% 1%' }}>
          <thead>
            <tr>
              {filteredColumns?.map(column => (
                <th key={column.key} onClick={() => handleSort(column.key as keyof Payment)} style={{ cursor: 'pointer' }}>
                  {column.label} {getSortIcon(column.key as keyof Payment)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedPayment.length === 0 ? (
              <tr>
                <td colSpan={filteredColumns.length} style={{ textAlign: 'center' }}>
                  No payment methods added yet
                </td>
              </tr>
            ) : (
              paginatedPayment?.map((user, index) => (
                <tr key={user.id}>
                  {columnKeys.includes('id') && <td>{index + 1}</td>}
                  {columnKeys.includes('paymentType') && <td>{user.paymentType}</td>}
                  {columnKeys.includes('deliveryStatus') && (
                    <td>{getStatusLabel(user.deliveryStatus)}</td>
                  )}
                  {columnKeys.includes('actions') && (
                    <td>
                      <img onClick={() => handleEdit(user.id)} style={{ width: '16px', marginRight: '10px', cursor: 'pointer' }} src={EditIcon} alt="edit-icon" />
                      <img onClick={() => handleOpen(user)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <Pagination
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          size="md"
          total={users.length}
          limit={limit}
          limitOptions={[30, 50]}
          activePage={activePage}
          onChangePage={handlePageChange}
          onChangeLimit={handleLimitChange}
          style={{ marginLeft: '4%', marginRight: '4%' }}
        />
        <Modal backdrop="static" role="alertdialog" open={open} onClose={handleClose} size="xs">
          <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <RemindIcon style={{ color: '#ffb300', fontSize: 24, marginBottom: '4%' }} />
            Are you sure you want to delete the service "{selectedUser?.paymentType}"?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleDelete} style={{ marginRight: '2%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)' }}>
              Delete
            </Button>
            <Button onClick={handleClose} style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)' }}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PaymentManagement;
