import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TotalAmount from "../../assets/dashboard/Total-Amount.svg";
import BalanceAmount from "../../assets/dashboard/Balance-amount.svg";
import Delivered from "../../assets/dashboard/Delivered.svg";
import PaymentPending from "../../assets/dashboard/payment-pending.svg";
import PickedUp from "../../assets/dashboard/picked-up.svg";
import Processing from "../../assets/dashboard/processing.svg";
import ReadyForDelivery from "../../assets/dashboard/Ready-for-delivery.svg";
import ReceivedAmount from "../../assets/dashboard/ReceivedAmount.svg";
import ToBePickedUp from "../../assets/dashboard/To-be-Picked-up.svg";
import "./Dashboard.css";
import { callApi } from "../utils/api";

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboard, setDashboard] = useState<any>(null);
  const [roleType, setRoleType] = useState('');

  useEffect(() => {
    getDashboard();
    const role: any = localStorage.getItem('role');
    setRoleType(role);
  }, [roleType]);

  const getDashboard = () => {
    callApi("POST", "dashBoard.php", '')
      .then((res: any) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          setDashboard(response.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const handleNavigation = (path: any) => {
    navigate(path);
  };

  return (
    <div className='dashboard'>
      <div className="card-container">
        {dashboard && (
          <>
            {roleType === '1' && (
              <><div className="card-section" style={{cursor: 'default'}}>
                <a style={{ color: "black", textDecoration: "none" }}>
                  <div className="card-details">
                    <span className="card-title">Total Amount</span>
                    <img src={TotalAmount} alt="Hot leads" className="leads-icon" />
                  </div>
                  <h1 className="card-info">{Math.floor(dashboard.totalAmount) || '0'}</h1>
                </a>
              </div><div className="card-section" style={{cursor: 'default'}}>
                  <a style={{ color: "black", textDecoration: "none" }}>
                    <div className="card-details">
                      <span className="card-title">Received Amount</span>
                      <img src={ReceivedAmount} alt="Hot leads" className="leads-icon" />
                    </div>
                    <h1 className="card-info">{dashboard.receivedAmount || '0'}</h1>
                  </a>
                </div><div className="card-section" style={{cursor: 'default'}}>
                  <a style={{ color: "black", textDecoration: "none" }}>
                    <div className="card-details">
                      <span className="card-title">Balance Amount</span>
                      <img src={BalanceAmount} alt="Hot leads" className="leads-icon" />
                    </div>
                    <h1 className="card-info">{dashboard.balanceAmount || '0'}</h1>
                  </a>
                </div></>
            )}

            {/* Hot Leads */}
            <div className="card-section" onClick={() => handleNavigation('/layout/orders/1')}>
              <a style={{ color: "black", textDecoration: "none" }}>
                <div className="card-details">
                  <span className="card-title">To be Picked Up</span>
                  <img src={ToBePickedUp} alt="Hot leads" className="leads-icon" />
                </div>
                <h1 className="card-info">{dashboard.toepickedup || '0'}</h1>
              </a>
            </div>
            {/* Warm Leads */}
            <div className="card-section" onClick={() => handleNavigation('/layout/orders/2')}>
              <a style={{ color: "black", textDecoration: "none" }}>
                <div className="card-details">
                  <span className="card-title">Picked Up</span>
                  <img src={PickedUp} alt="Warm Leads" className="leads-icon" />
                </div>
                <h1 className="card-info">{dashboard.pickedup || '0'}</h1>
              </a>
            </div>
            {/* Cold Leads */}
            <div className="card-section" onClick={() => handleNavigation('/layout/orders/3')}>
              <a style={{ color: "black", textDecoration: "none" }}>
                <div className="card-details">
                  <span className="card-title">Processing</span>
                  <img src={Processing} alt="Cold Leads" className="leads-icon" />
                </div>
                <h1 className="card-info">{dashboard.processing || '0'}</h1>
              </a>
            </div>
            <div className="card-section" onClick={() => handleNavigation('/layout/orders/4')}>
              <a style={{ color: "black", textDecoration: "none" }}>
                <div className="card-details">
                  <span className="card-title">Ready for Delivery</span>
                  <img src={ReadyForDelivery} alt="Cold Leads" className="leads-icon" />
                </div>
                <h1 className="card-info">{dashboard.ReadyForDelivery || '0'}</h1>
              </a>
            </div>
            {(roleType === '1' || roleType === '3') &&
              <>
                <div className="card-section" onClick={() => handleNavigation('/layout/orders/5')}>
                  <a style={{ color: "black", textDecoration: "none" }}>
                    <div className="card-details">
                      <span className="card-title">Payment Pending</span>
                      <img src={PaymentPending} alt="Cold Leads" className="leads-icon" />
                    </div>
                    <h1 className="card-info">{dashboard.deliveredPaymentPending || '0'}</h1>
                  </a>
                </div><div className="card-section" onClick={() => handleNavigation('/layout/orders/6')}>
                  <a style={{ color: "black", textDecoration: "none" }}>
                    <div className="card-details">
                      <span className="card-title">Delivered</span>
                      <img src={Delivered} alt="Cold Leads" className="leads-icon" />
                    </div>
                    <h1 className="card-info">{dashboard.Delivered || '0'}</h1>
                  </a>
                </div>
              </>
            }
          </>
        )}
      </div>
    </div>
  )
}

export default Dashboard;
