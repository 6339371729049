import React, { useState, useEffect } from "react";
import "./AddLead.css";
import { Button, CheckboxGroup, DatePicker, Modal } from 'rsuite';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate, useLocation } from 'react-router-dom';
import { showError, showSuccess } from "../utils/sweetAlert";
import { callApi } from "../utils/api";
import * as XLSX from 'xlsx';
import { Container, Form } from "react-bootstrap";
import ViewOrder from "../../assets/eye.png";
import ViewAttachments from "../../assets/file.png";
import AddNew from "../../assets/header/addNew.svg";
import OrderHistory from '../../assets/history.png';
import Filter from "../../assets/filter-new.svg";
import ExcelFiles from "./ExcelFiles";
import deleteIcon from "../../assets/delete.png";
import RemindIcon from '@rsuite/icons/legacy/Remind';
import Pagination from 'rsuite/Pagination';

interface Order {
  orderid: string;
  productName: string;
  quantity: number;
  price: number;
}

interface LeadData {
  pickupDate: string;
  apartmentName: string;
  flatNo: string;
  mobileNo: string;
  status: string;
  service: string;
  expectedDeliveryDate: string;
  deliveryPersonName: string;
  ironPersonName: string;
  category: string;
  comment: string;
  orders: Order[];
  apartmentId: string;
  deliveryPersonId: string;
  ironPersonId: string;
  customerId: string;
  serviceName: string;
  orderId: string;
  id: string;
  sno: string;
}

interface Apartment {
  id: number;
  apartmentName: string;
  checked: boolean;
}

interface Person {
  id: number;
  name: string;
  checked: boolean;
}

const AddLead = () => {
  const [formData, setFormData] = useState<LeadData>({
    pickupDate: '',
    apartmentName: '',
    flatNo: '',
    mobileNo: '',
    status: '',
    service: '',
    expectedDeliveryDate: '',
    deliveryPersonName: '',
    ironPersonName: '',
    category: '',
    comment: '',
    orders: [],
    apartmentId: '',
    deliveryPersonId: '',
    ironPersonId: '',
    customerId: '',
    serviceName: '',
    orderId: '',
    id: '',
    sno: '',
  });
  const [errors, setErrors] = useState({
    apartmentName: '',
    flatNo: '',
    mobileNo: '',
    service: '',
    category: '',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const status = pathArray[pathArray.length - 1];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [bulkFormData, setBulkFormData] = useState<LeadData[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<LeadData | null>(null);
  const [selectedAttachment, setSelectedAttachment] = useState<{ id?: string; attachment?: any[] } | null>(null);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [service, setService] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [leads, setLeads] = useState<LeadData[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof LeadData | null, direction: 'asc' | 'desc' } | null>(null);
  const [excelData, setExcelData] = useState<LeadData[]>([]);
  const [open, setOpen] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const [captureMode, setCaptureMode] = useState(false);
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [openOrderHistory, setOpenOrderHistory] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [apartment, setApartment] = useState<Apartment[]>([]);
  const [ironPersons, setIronPersons] = useState<Person[]>([]);
  const [deliveryPersons, setDeliveryPersons] = useState<Person[]>([]);
  const [itemFiles, setItemFiles] = useState<{ attachmentId: string; deleteAttachementStatus: false, imageType: string; imageData: string; }[]>([]);
  const [selectedApartmentIds, setSelectedApartmentIds] = useState<number[]>([]);
  const [selectedIronPersonIds, setSelectedIronPersonIds] = useState<number[]>([]);
  const [selectedDeliveryPersonIds, setSelectedDeliveryPersonIds] = useState<number[]>([]);
  const [deleteOrder, setDeleteOrder] = useState<LeadData | null>(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [filterDates, setFilterDates] = useState({
    fromDate: null,
    toDate: null,
    cleared: false
  });

  const start = (activePage - 1) * limit;
  const end = start + limit;
  const paginatedLeads = leads.slice(start, end);

  const handlePageChange = (page: any) => {
    setActivePage(page);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setActivePage(1);
  };

  const handleApartmentChange = (id: number) => {
    const updatedApartments = apartment.map((apt: any) =>
      apt.id == id ? { ...apt, checked: !apt.checked } : apt
    );
    setApartment(updatedApartments);
    const newSelectedIds = updatedApartments
      .filter((apt: any) => apt.checked)
      .map((apt: any) => apt.id);
    setSelectedApartmentIds(newSelectedIds);
  };

  const handleIronPersonChange = (id: number) => {
    const updatedIronPersons = ironPersons.map((person) =>
      person.id === id ? { ...person, checked: !person.checked } : person
    );
    setIronPersons(updatedIronPersons);
    const newSelectedIds = updatedIronPersons
      .filter((person: any) => person.checked)
      .map((person: any) => person.id);
    setSelectedIronPersonIds(newSelectedIds);
  };

  const handleDeliveryPersonChange = (id: number) => {
    const updatedDeliveryPersons = deliveryPersons.map((person) =>
      person.id === id ? { ...person, checked: !person.checked } : person
    );
    setDeliveryPersons(updatedDeliveryPersons);
    const newSelectedIds = updatedDeliveryPersons
      .filter((person: any) => person.checked)
      .map((person: any) => person.id);
    setSelectedDeliveryPersonIds(newSelectedIds);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getLeadsData();
    getApartment();
    getIronPersons();
    getDeliveryPersons();
    console.log(status);
    setFilterDates({ fromDate: null, toDate: null, cleared: false });
  }, [status, searchTerm])

  const getLeadsData = () => {
    setFilterDates({ fromDate: null, toDate: null, cleared: false });
    let req = {
      page: 1,
      limit: 50,
      searchTerm: searchTerm,
      status: status,
      ironPersonId: selectedIronPersonIds,
      apartmentId: selectedApartmentIds,
      deliveryPersonId: selectedDeliveryPersonIds,
      fromDate: null,
      toDate: null,
    };
    callApi("POST", "leadGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setLeads(response['list']);
          setSelectedApartmentIds([]);
          setSelectedIronPersonIds([]);
          setSelectedDeliveryPersonIds([]);
          setIsFilterOpen(false);
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getOrderHistory = (id: any) => {
    let req = {
      id: id
    };
    callApi("POST", "orderHistory.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setOrderHistory(response.data);
          console.log("order history", response)
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getAutoSuggestion = (value: any) => {
    setSuggestions([]);
    let req = {
      searchTerm: value
    };
    callApi("POST", "apartmentSuggestion.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setSuggestions(response.list);
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleRowClick = (lead: LeadData) => {
    const updatedLead = { ...lead, status: status };
    console.log("updatedLead--->", updatedLead);

    navigate("/layout/status-change", { state: { lead: updatedLead } });
  };

  const handleOrderClick = (lead: LeadData) => {
    console.log("lead--->", lead);

    setSelectedOrder(lead);
    setOpen(true);
  };

  const handleAttachmentClick = (lead: any) => {
    setSelectedAttachment(lead);
    setPreviewImages([]);
    console.log(lead);
    setAttachmentOpen(true);
  }

  const handleOrderHistory = (lead: any) => {
    getOrderHistory(lead.id);
    setOpenOrderHistory(true);
  }

  const getService = () => {
    let req = {
      page: '',
      limit: '',
      searchTerm: '',
    };
    callApi("POST", "serviceList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setService(response["list"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getApartment = () => {
    let req = {
      page: '',
      limit: '',
      searchTerm: searchTerm,
    };
    callApi("POST", "apartmentList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          const apartmentsWithChecked = response["list"].map((apt: any) => ({
            ...apt,
            checked: false,
          }));
          setApartment(apartmentsWithChecked);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getIronPersons = () => {
    let req = {
      roleId: 2,
    };

    callApi("POST", "userRoleGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          const ironPersonsWithChecked = response.list.map((person: any) => ({
            ...person,
            checked: false,
          }));
          setIronPersons(ironPersonsWithChecked);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDeliveryPersons = () => {
    let req = {
      roleId: 3,
    };

    callApi("POST", "userRoleGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          const deliveryPersonsWithChecked = response.list.map((person: any) => ({
            ...person,
            checked: false,
          }));
          setDeliveryPersons(deliveryPersonsWithChecked);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
    setErrors({
      ...errors,
      [id]: ''
    });
    if (id === "apartmentName" && value.length > 0) {
      getAutoSuggestion(value);
    }
    if (id === "apartmentName" && value.length == 0) {
      setSuggestions([]);
    }
  };

  // useEffect(() => {
  //   setCurrentIndex(-1);
  // }, [suggestions]);

  const handleSuggestionClick = (suggestion: any) => {
    setFormData({
      ...formData,
      apartmentName: suggestion.apartmentName,
      apartmentId: suggestion.apartmentId,
      flatNo: suggestion.flatNo,
      mobileNo: suggestion.mobileNo,
      deliveryPersonName: suggestion.deliveryPersonName,
      deliveryPersonId: suggestion.deliveryPersonId,
      ironPersonName: suggestion.ironPersonName,
      ironPersonId: suggestion.ironPersonId,
    });
    setSuggestions([]);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setCurrentIndex((prevIndex) => (prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1));
    } else if (e.key === 'Enter' && currentIndex >= 0) {
      e.preventDefault();
      handleSuggestionClick(suggestions[currentIndex]);
    }
  };

  const handleKeyPress = (event: any) => {
    const { value } = event.target;

    if (event.key === ' ' && value.endsWith(' ')) {
      event.preventDefault();
    }
  };
  const validate = () => {
    let validationErrors: any = {};
    if (!formData.apartmentName) {
      validationErrors.apartmentName = 'Apartment Name and Flat No. is required';
    }

    if (!formData.service) {
      validationErrors.service = 'Service Needed is required';
    }

    if (!formData.mobileNo) {
      validationErrors.mobileNo = 'Phone number is required';
    }

    if (!formData.category) {
      validationErrors.category = 'Order Urgency is required';
    }
    return validationErrors;
  };

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleDateChange = (value: any, id: string) => {
    const formattedDate = `${value.getFullYear()}-${String(value.getMonth() + 1).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')}`;
    setFormData((prevData) => ({ ...prevData, [id]: formattedDate }));
  };

  const handleSubmit = (e: any) => {
    const form = e.target.form;
    if (form.checkValidity() === false) {
      console.log("Form validation failed.");
      return;
    }
    e.preventDefault();
    setLoading(true);
    let formattedPickupDate = formData.pickupDate
      ? new Date(formData.pickupDate).toISOString().split('T')[0]
      : '';
    let formattedDeliveryDate = formData.expectedDeliveryDate
      ? new Date(formData.expectedDeliveryDate).toISOString().split('T')[0]
      : '';

    let req = {
      apartmentName: formData.apartmentId,
      mobileNo: formData.mobileNo,
      flatNo: formData.flatNo,
      pickupDate: formattedPickupDate,
      service: formData.service,
      expectedDeliveryDate: formattedDeliveryDate,
      comments: formData.comment,
      category: formData.category,
      deliveryPersonId: formData.deliveryPersonId,
      ironPersonId: formData.ironPersonId,
    };
    callApi("POST", "leadCreate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setIsModalOpen(false);
          setLoading(false);
          showSuccess("Success!", "Order created successfully!");
          setFormData({
            pickupDate: '',
            apartmentName: '',
            flatNo: '',
            mobileNo: '',
            status: '',
            service: '',
            expectedDeliveryDate: '',
            deliveryPersonName: '',
            ironPersonName: '',
            category: '',
            comment: '',
            orders: [],
            apartmentId: '',
            deliveryPersonId: '',
            ironPersonId: '',
            customerId: '',
            serviceName: '',
            orderId: '',
            id: '',
            sno: '',
          });
          getLeadsData();
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        let data: string | ArrayBuffer = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        let labelsArray: LeadData[] = [];

        workbook.SheetNames.forEach((sheetName: any) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });

          const extractedData = sheetData.slice(1).map((row: any[]): LeadData | null => {
            if (row[1] && row[2]) {
              return {
                apartmentName: row[1],
                flatNo: row[2],
                mobileNo: row[0],
              } as LeadData;
            }
            return null;
          }).filter((item): item is LeadData => item !== null);
          console.log('extractedData', extractedData)
          labelsArray.push(...extractedData);
          console.log('labelsArray', labelsArray)
        });
        setExcelData(labelsArray);
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleBulkSubmit = () => {
    setBulkLoading(true);
    let req = excelData;
    callApi("POST", "customerUpload.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setIsBulkModalOpen(false);
          showSuccess("Success!", "Customers added successfully!");
        } else {
          showError("Failed", response["message"]);
        }
      })
      .catch((err) => {
        console.log(err);
        showError("Failed", err.response.data.message);
      });
    setBulkLoading(false);
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleAttachmentChange = (event: any) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const newIcons: any[] = [];

      files.forEach((file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          const base64String = reader.result as string;
          const imageType = file.type.split("/")[1];
          const newIcon = {
            attachmentId: '',
            deleteAttachementStatus: false,
            imageType: imageType,
            imageData: base64String
          };
          newIcons.push(newIcon);
          if (newIcons.length === files.length) {
            setItemFiles((prevItemFiles: any) => {
              const updatedItemFiles = Array.isArray(prevItemFiles) ? prevItemFiles : [];
              return [...updatedItemFiles, ...newIcons];
            });
            setPreviewImages((prevPreviewImages) => {
              const updatedPreviewImages = Array.isArray(prevPreviewImages) ? prevPreviewImages : [];
              return [...updatedPreviewImages, ...newIcons.map(icon => icon.imageData)];
            });
          }
        };
      });
    }
  };

  useEffect(() => {
    console.log(selectedAttachment)
  }, [searchTerm])

  const handleFrontBrowseClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    } console.log('Button clicked');
  };

  const handleAttachmentSubmit = () => {
    setImageLoading(true)
    let req = {
      leadId: selectedAttachment?.id,
      deleteAttachmentId: [],
      attachment: itemFiles,
    };
    callApi("POST", "attachmentUpload.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          showSuccess("Success", response.message);
          getLeadsData();
          setAttachmentOpen(false);
          setPreviewImages([]);
          setImageLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleImageUpload = () => {
    setImageUpload(true);
  }

  const handleCameraClick = () => {
    setCaptureMode(true);
    setImageUpload(false);
    handleFrontBrowseClick();
  };

  const handleGalleryClick = () => {
    setCaptureMode(false);
    setImageUpload(false);
    handleFrontBrowseClick();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    getService();
    setFormData({
      pickupDate: '',
      apartmentName: '',
      flatNo: '',
      mobileNo: '',
      status: '',
      service: '',
      expectedDeliveryDate: '',
      deliveryPersonName: '',
      ironPersonName: '',
      category: '',
      comment: '',
      orders: [],
      apartmentId: '',
      deliveryPersonId: '',
      ironPersonId: '',
      customerId: '',
      serviceName: '',
      orderId: '',
      id: '',
      sno: '',
    });
    setErrors({
      apartmentName: '',
      flatNo: '',
      mobileNo: '',
      service: '',
      category: '',
    })
  }

  const handleDelete = () => {
    if (deleteOrder) {
      console.log(`Delete user with ID: ${deleteOrder.id}`);
      let req = {
        id: deleteOrder.id
      };
      callApi("POST", "orderDelete.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          if (response["success"] === true) {
            showSuccess("Success", response.message);
            getLeadsData();
            setDeleteOpen(false);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    handleClose();
  };

  const ascIcon = "↑";
  const descIcon = "↓";

  const handleSort = (key: keyof LeadData) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...leads].sort((a: any, b: any) => {
      if (key === "sno") {
        return direction === 'asc' ? a.sno - b.sno : b.sno - a.sno;
      } else {
        if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        return 0;
      }
    });
    setLeads(sortedData);
  };

  const getSortIcon = (key: keyof LeadData) => {
    if (sortConfig?.key === key) {
      return sortConfig.direction === 'asc' ? ascIcon : descIcon;
    }
    return null;
  };

  const [role, setRole] = useState("");

  useEffect(() => {
    const userRole = localStorage.getItem('role');
    if (userRole) {
      setRole(userRole);
    }
  }, []);

  const handleOpen = (user: any) => {
    setDeleteOrder(user);
    setDeleteOpen(true);
  };

  const handleFilterDate = (date: any, dateString: any, type: any) => {
    const formattedDate = date
      ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
      : null;
    console.log(date, dateString, formattedDate, type)
    setFilterDates((prevDates) => ({
      ...prevDates,
      [type]: formattedDate,
    }));
  };

  useEffect(() => {
    if(filterDates.cleared == true) {
    getLeadsFilteredData();
    }
    console.log('filteredDate', filterDates)
  }, [filterDates])

  const handleClearDate = (type: any) => {
     setFilterDates((prevDates) => ({
      ...prevDates,
      [type]: null,
      cleared: true,
    }));
  };

  const getLeadsFilteredData = () => {
    console.log("call api", filterDates);
    if(filterDates.fromDate == null || filterDates.toDate == null) {
      showError("Error", "Please select date range to filter the orders.");
      // return;
    }
    let req = {
      page: 1,
      limit: 50,
      searchTerm: searchTerm,
      status: status,
      ironPersonId: selectedIronPersonIds,
      apartmentId: selectedApartmentIds,
      deliveryPersonId: selectedDeliveryPersonIds,
      fromDate: filterDates.fromDate,
      toDate: filterDates.toDate,
    };
    callApi("POST", "leadGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setLeads(response['list']);
          setSelectedApartmentIds([]);
          setSelectedIronPersonIds([]);
          setSelectedDeliveryPersonIds([]);
          setIsFilterOpen(false);
          setFilterDates((prevDates) => ({
            ...prevDates,
            cleared: false,
          }));
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <>
      {status !== '0' && (
        <div className="mobile-row" style={{ display: 'flex', alignItems: 'center', margin: '20px 4%' }}>
          <div style={{ width: '50%' }}>
            <DatePicker
              id="filterDateFrom"
              format="dd/MM/yyyy"
              placeholder="From Date"
              style={{ marginRight: '10px' }}
              onChange={(date, dateString) => handleFilterDate(date, dateString, 'fromDate')}
              oneTap
              className="from-date"
              value={filterDates.fromDate ? new Date(filterDates.fromDate) : null}
              onClean={() => handleClearDate('fromDate')}
            />
            <DatePicker
              id="filterDateTo"
              format="dd/MM/yyyy"
              placeholder="To Date"
              oneTap
              onChange={(date, dateString) => handleFilterDate(date, dateString, 'toDate')}
              className="to-date"
              placement="bottomEnd"
              onClean={() => handleClearDate('toDate')}
              value={filterDates.toDate ? new Date(filterDates.toDate) : null}
            />
            <Button style={{ marginLeft: '10px', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} onClick={getLeadsFilteredData}>
              Filter
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchTerm}
              className="search-field"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '60%', marginRight: '10px' }}
            />
            <div className="filter" style={{ marginRight: '10px' }}>
              <img src={Filter} alt="icon" onClick={() => setIsFilterOpen(true)} />
            </div>
          </div>
        </div>
      )}
      {status === '1' && (
        <div className="custom-table">
          <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>To Be Picked Up</h5>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
            <Button style={{ marginRight: '1%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} onClick={handleOpenModal}>
              Add Order
            </Button>
            <Button style={{ marginRight: '1%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} onClick={() => setIsBulkModalOpen(true)}>
              Bulk Upload
            </Button>
            <div style={{ marginRight: '4%' }}>
              <ExcelFiles data={leads} status='To-be-picked-up' />
            </div>
          </div>
          <Table bordered responsive hover style={{ width: '92%', margin: '2% 4%' }}>
            <thead>
              <tr>
                <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer' }}>
                  S.No {getSortIcon("sno")}
                </th>
                <th onClick={() => handleSort('orderId')} style={{ cursor: 'pointer' }}>Order Id{getSortIcon('orderId')}</th>
                <th><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></th>
                <th onClick={() => handleSort('apartmentName')} style={{ cursor: 'pointer' }}>Apartment Name{getSortIcon('apartmentName')}</th>
                <th onClick={() => handleSort('flatNo')} style={{ cursor: 'pointer' }}>Flat No.{getSortIcon('flatNo')}</th>
                {role === '1' && (<th>Phone Number</th>)}
                <th>Attachments</th>
                <th>Order History</th>
                {role === '1' && (<th>Action</th>)}
              </tr>
            </thead>
            <tbody>
              {paginatedLeads.length === 0 ? (
                <tr>
                  <td colSpan={8} style={{ textAlign: "center" }}>
                    No Orders yet
                  </td>
                </tr>
              ) : (
                paginatedLeads.map((lead, index) => (
                  <tr
                    key={index}
                    className={lead.category === 'hot' ? 'priority' : ''}
                  >
                    <td>{index + 1}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.orderId}</td>
                    <td onClick={() => handleOrderClick(lead)} style={{ cursor: 'pointer', color: 'blue' }} ><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.apartmentName}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.flatNo}</td>
                    {role === '1' && (<td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.mobileNo}</td>)}
                    <td onClick={() => handleAttachmentClick(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={ViewAttachments} alt="icon" /></td>
                    <td onClick={() => handleOrderHistory(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={OrderHistory} alt="icon" /></td>
                    {role === '1' && (<td>
                      <img onClick={() => handleOpen(lead)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>)}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      )}

      {status == '2' && (
        <div className="custom-table">
          <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Picked Up</h5>
          <div style={{ marginRight: '4%', display: 'flex', justifyContent: 'flex-end' }}>
            <ExcelFiles data={leads} status='picked-up' />
          </div>
          <Table bordered responsive hover style={{ width: '92%', margin: '2% 4%' }}>
            <thead>
              <tr>
                <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer' }}>
                  S.No {getSortIcon("sno")}
                </th>
                <th onClick={() => handleSort('orderId')} style={{ cursor: 'pointer' }}>Order Id{getSortIcon('orderId')}</th>
                <th><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></th>
                <th onClick={() => handleSort('apartmentName')} style={{ cursor: 'pointer' }}>Apartment Name{getSortIcon('apartmentName')}</th>
                <th onClick={() => handleSort('flatNo')} style={{ cursor: 'pointer' }}>Flat No.{getSortIcon('flatNo')}</th>
                {role === '1' && (<th>Phone Number</th>)}
                <th>Attachments</th>
                <th>Order History</th>
                {role === '1' && (<th>Action</th>)}
              </tr>
            </thead>
            <tbody>
              {paginatedLeads.length === 0 ? (
                <tr>
                  <td colSpan={10} style={{ textAlign: "center" }}>
                    No Orders yet
                  </td>
                </tr>
              ) : (
                paginatedLeads.map((lead, index) => (
                  <tr
                    key={index}
                    className={lead.category === 'hot' ? 'priority' : ''}
                  >
                    <td>{index + 1}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.orderId}</td>
                    <td onClick={() => handleOrderClick(lead)} style={{ cursor: 'pointer', color: 'blue' }} ><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.apartmentName}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.flatNo}</td>
                    {role === '1' && (<td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.mobileNo}</td>)}
                    <td onClick={() => handleAttachmentClick(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={ViewAttachments} alt="icon" /></td>
                    <td onClick={() => handleOrderHistory(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={OrderHistory} alt="icon" /></td>
                    {role === '1' && (<td>
                      <img onClick={() => handleOpen(lead)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>)}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      )}

      {status === '3' && (
        <div className="custom-table">
          <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Processing</h5>
          <div style={{ marginRight: '4%', display: 'flex', justifyContent: 'flex-end' }}>
            <ExcelFiles data={leads} status='processing' />
          </div>
          <Table bordered responsive hover style={{ width: '92%', margin: '2% 4%' }}>
            <thead>
              <tr>
                <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer' }}>
                  S.No {getSortIcon("sno")}
                </th>
                <th onClick={() => handleSort('orderId')} style={{ cursor: 'pointer' }}>Order Id{getSortIcon('orderId')}</th>
                <th><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></th>
                <th onClick={() => handleSort('apartmentName')} style={{ cursor: 'pointer' }}>Apartment Name{getSortIcon('apartmentName')}</th>
                <th onClick={() => handleSort('flatNo')} style={{ cursor: 'pointer' }}>Flat No.{getSortIcon('flatNo')}</th>
                {role === '1' && (<th>Phone Number</th>)}
                <th>Attachments</th>
                <th>Order History</th>
                {role === '1' && (<th>Action</th>)}
              </tr>
            </thead>
            <tbody>
              {paginatedLeads.length === 0 ? (
                <tr>
                  <td colSpan={10} style={{ textAlign: "center" }}>
                    No Orders yet
                  </td>
                </tr>
              ) : (
                paginatedLeads.map((lead, index) => (
                  <tr
                    key={index}
                    className={lead.category === 'hot' ? 'priority' : ''}
                  >
                    <td>{index + 1}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.orderId}</td>
                    <td onClick={() => handleOrderClick(lead)} style={{ cursor: 'pointer', color: 'blue' }} ><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.apartmentName}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.flatNo}</td>
                    {role === '1' && (<td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.mobileNo}</td>)}
                    <td onClick={() => handleAttachmentClick(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={ViewAttachments} alt="icon" /></td>
                    <td onClick={() => handleOrderHistory(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={OrderHistory} alt="icon" /></td>
                    {role === '1' && (<td>
                      <img onClick={() => handleOpen(lead)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>)}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      )}

      {status === '4' && (
        <div className="custom-table">
          <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Ready For Delivery</h5>
          <div style={{ marginRight: '4%', display: 'flex', justifyContent: 'flex-end' }}>
            <ExcelFiles data={leads} status='ready-for-delivery' />
          </div>
          <Table responsive bordered hover style={{ width: '92%', margin: '2% 4%' }}>
            <thead>
              <tr>
                <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer' }}>
                  S.No {getSortIcon("sno")}
                </th>
                <th onClick={() => handleSort('orderId')} style={{ cursor: 'pointer' }}>Order Id{getSortIcon('orderId')}</th>
                <th><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></th>
                <th onClick={() => handleSort('apartmentName')} style={{ cursor: 'pointer' }}>Apartment Name{getSortIcon('apartmentName')}</th>
                <th onClick={() => handleSort('flatNo')} style={{ cursor: 'pointer' }}>Flat No.{getSortIcon('flatNo')}</th>
                {role === '1' && (<th>Phone Number</th>)}
                <th>Attachments</th>
                <th>Order History</th>
                {role === '1' && (<th>Action</th>)}
              </tr>
            </thead>
            <tbody>
              {paginatedLeads.length === 0 ? (
                <tr>
                  <td colSpan={10} style={{ textAlign: "center" }}>
                    No Orders yet
                  </td>
                </tr>
              ) : (
                paginatedLeads.map((lead, index) => (
                  <tr
                    key={index}
                    className={lead.category === 'hot' ? 'priority' : ''}
                  >
                    <td>{index + 1}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.orderId}</td>
                    <td onClick={() => handleOrderClick(lead)} style={{ cursor: 'pointer', color: 'blue' }} ><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.apartmentName}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.flatNo}</td>
                    {role === '1' && (<td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.mobileNo}</td>)}
                    <td onClick={() => handleAttachmentClick(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={ViewAttachments} alt="icon" /></td>
                    <td onClick={() => handleOrderHistory(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={OrderHistory} alt="icon" /></td>
                    {role === '1' && (<td>
                      <img onClick={() => handleOpen(lead)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>)}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      )}

      {status === '5' && (
        <div className="custom-table">
          <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Payment Pending</h5>
          <div style={{ marginRight: '4%', display: 'flex', justifyContent: 'flex-end' }}>
            <ExcelFiles data={leads} status='payment-pending' />
          </div>
          <Table responsive bordered hover style={{ width: '92%', margin: '2% 4%' }}>
            <thead>
              <tr>
                <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer' }}>
                  S.No {getSortIcon("sno")}
                </th>
                <th onClick={() => handleSort('orderId')} style={{ cursor: 'pointer' }}>Order Id{getSortIcon('orderId')}</th>
                <th><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></th>
                <th onClick={() => handleSort('apartmentName')} style={{ cursor: 'pointer' }}>Apartment Name{getSortIcon('apartmentName')}</th>
                <th onClick={() => handleSort('flatNo')} style={{ cursor: 'pointer' }}>Flat No.{getSortIcon('flatNo')}</th>
                {role === '1' && (<th>Phone Number</th>)}
                <th>Attachments</th>
                <th>Order History</th>
                {role === '1' && (<th>Action</th>)}
              </tr>
            </thead>
            <tbody>
              {paginatedLeads.length === 0 ? (
                <tr>
                  <td colSpan={10} style={{ textAlign: "center" }}>
                    No Orders yet
                  </td>
                </tr>
              ) : (
                paginatedLeads.map((lead, index) => (
                  <tr
                    key={index}
                    className={lead.category === 'hot' ? 'priority' : ''}
                  >
                    <td>{index + 1}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.orderId}</td>
                    <td onClick={() => handleOrderClick(lead)} style={{ cursor: 'pointer', color: 'blue' }} ><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.apartmentName}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.flatNo}</td>
                    {role === '1' && (<td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.mobileNo}</td>)}
                    <td onClick={() => handleAttachmentClick(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={ViewAttachments} alt="icon" /></td>
                    <td onClick={() => handleOrderHistory(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={OrderHistory} alt="icon" /></td>
                    {role === '1' && (<td>
                      <img onClick={() => handleOpen(lead)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>)}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      )}

      {status === '6' && (
        <div className="custom-table">
          <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Delivered</h5>
          <div style={{ marginRight: '4%', display: 'flex', justifyContent: 'flex-end' }}>
            <ExcelFiles data={leads} status='delivered' />
          </div>
          <Table responsive bordered hover style={{ width: '92%', margin: '2% 4%' }}>
            <thead>
              <tr>
                <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer' }}>
                  S.No {getSortIcon("sno")}
                </th>
                <th onClick={() => handleSort('orderId')} style={{ cursor: 'pointer' }}>Order Id{getSortIcon('orderId')}</th>
                <th><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></th>
                <th onClick={() => handleSort('apartmentName')} style={{ cursor: 'pointer' }}>Apartment Name{getSortIcon('apartmentName')}</th>
                <th onClick={() => handleSort('flatNo')} style={{ cursor: 'pointer' }}>Flat No.{getSortIcon('flatNo')}</th>
                {role === '1' && (<th>Phone Number</th>)}
                <th>Attachments</th>
                <th>Order History</th>
                {role === '1' && (<th>Action</th>)}
              </tr>
            </thead>
            <tbody>
              {paginatedLeads.length === 0 ? (
                <tr>
                  <td colSpan={10} style={{ textAlign: "center" }}>
                    No Orders yet
                  </td>
                </tr>
              ) : (
                paginatedLeads.map((lead, index) => (
                  <tr
                    key={index}
                    className={lead.category === 'hot' ? 'priority' : ''}
                  >
                    <td>{index + 1}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.orderId}</td>
                    <td onClick={() => handleOrderClick(lead)} style={{ cursor: 'pointer', color: 'blue' }} ><img style={{ width: '25px' }} src={ViewOrder} alt="icon" /></td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.apartmentName}</td>
                    <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.flatNo}</td>
                    {role === '1' && (<td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.mobileNo}</td>)}
                    <td onClick={() => handleAttachmentClick(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={ViewAttachments} alt="icon" /></td>
                    <td onClick={() => handleOrderHistory(lead)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={OrderHistory} alt="icon" /></td>
                    {role === '1' && (<td>
                      <img onClick={() => handleOpen(lead)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>)}
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      )}

      <Pagination
        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
        size="md"
        total={leads.length}
        limit={limit}
        limitOptions={[30, 50]}
        activePage={activePage}
        onChangePage={handlePageChange}
        onChangeLimit={handleLimitChange}
        style={{ marginLeft: '4%', marginRight: '4%' }}
      />

      <Modal size="md" open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="contained-modal-title-vcenter" >
        <Modal.Header>
          <Modal.Title>Create New Order</Modal.Title>
        </Modal.Header><form >
          <Modal.Body style={{ marginLeft: "36px" }}>

            <div className="lead-form">
              <div className="leadformfields">
                <label htmlFor="apartmentName">Apartment Name - Flat No.<span style={{ color: 'red' }}>*</span></label>
                <input
                  className="addleadinput"
                  type="text"
                  id="apartmentName"
                  value={formData.apartmentName}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  required
                  autoComplete="off"
                  maxLength={50}
                />
              </div>
              {suggestions.length > 0 && (
                <div className="suggestions">
                  {suggestions.map((suggestion: any, index: any) => (
                    <div
                      key={suggestion.apartmentId}
                      className={`suggestion-item ${index === currentIndex ? 'highlighted' : ''}`}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.apartmentName} - {suggestion.mobileNo}
                    </div>
                  ))}
                </div>
              )}
              <div className="leadformfields">
                <label htmlFor="mobileNo">Phone Number<span style={{ color: 'red' }}>*</span></label>
                <input
                  className="addleadinput"
                  type="tel"
                  id="mobileNo"
                  value={formData.mobileNo}
                  onChange={handleInputChange}
                  maxLength={10}
                  minLength={10}
                  required
                  disabled
                />
              </div>
              <div className="leadformfields">
                <label htmlFor="service">Service Required<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="service"
                  className="addleadinput"
                  value={formData.service}
                  onChange={handleDropdownChange}
                  required
                >
                  <option value=""></option>
                  {service.map((service: any) => (
                    <option key={service.id} value={service.id}>
                      {service.service}
                    </option>
                  ))}
                </select>
              </div>
              <div className="leadformfields">
                <label htmlFor="pickupDate">Pickup Date</label>
                <DatePicker
                  id="pickupDate"
                  format="dd/MM/yyyy"
                  onChange={(value) => handleDateChange(value, 'pickupDate')}
                  value={formData.pickupDate ? new Date(formData.pickupDate) : null}
                  disabledDate={(date) => date ? date < new Date(new Date().setHours(0, 0, 0, 0)) : false}
                  oneTap
                />
              </div>
              <div className="leadformfields">
                <label htmlFor="expectedDeliveryDate">Delivery Expected Date</label>
                <DatePicker
                  id="expectedDeliveryDate"
                  format="dd/MM/yyyy"
                  onChange={(value) => handleDateChange(value, 'expectedDeliveryDate')}
                  value={formData.expectedDeliveryDate ? new Date(formData.expectedDeliveryDate) : null}
                  disabledDate={(date) => date ? date < new Date(new Date().setHours(0, 0, 0, 0)) : false}
                  oneTap
                />
              </div>
              <div className="leadformfields">
                <label htmlFor="deliveryPersonName">Assigned Delivery Person<span style={{ color: 'red' }}>*</span></label>
                <input
                  className="addleadinput"
                  type="text"
                  id="deliveryPersonName"
                  value={formData.deliveryPersonName}
                  onChange={handleInputChange}
                  required
                  maxLength={50}
                />
              </div>
              <div className="leadformfields">
                <label htmlFor="ironPersonName">Assigned Iron Person<span style={{ color: 'red' }}>*</span></label>
                <input
                  className="addleadinput"
                  type="text"
                  id="ironPersonName"
                  value={formData.ironPersonName}
                  onChange={handleInputChange}
                  required
                  maxLength={50}
                />
              </div>
              <div className="leadformfields">
                <label htmlFor="category">Urgency<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="category"
                  className="addleadinput"
                  value={formData.category}
                  onChange={handleDropdownChange}
                  required
                >
                  <option value=""></option>
                  <option value="hot">Hot</option>
                  <option value="warm">Warm</option>
                  <option value="cold">Cold</option>
                </select>
              </div>
              <div className="leadformfields">
                <label htmlFor="comment">Comment</label>
                <textarea
                  className="addleadinput"
                  id="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  maxLength={200}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setIsModalOpen(false)} appearance="subtle" disabled={loading}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} appearance="primary" type="submit" disabled={loading}>
              {loading ? 'Creating Order....' : 'Create Order'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal size="md" open={isBulkModalOpen} onClose={() => setIsBulkModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Bulk Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button className="sample">
            <a
              href="/add_lead.xlsx"
              download="Sample_Add_Order_Format.xlsx"
              className="atag"
            >
              Download Sample Order Format
            </a>
          </Button>
          <input style={{ marginLeft: '4%' }} type="file" accept=".xls,.xlsx,.csv" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsBulkModalOpen(false)} appearance="subtle" disabled={bulkLoading}>
            Cancel
          </Button>
          <Button onClick={handleBulkSubmit} appearance="primary" style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} disabled={bulkLoading}>
            {bulkLoading ? 'Uploading...' : 'Upload File'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="md" open={open} onClose={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6} className="order-info">Order Id</Col>
              <Col xs={6} className="order-info">{selectedOrder?.orderId}</Col>
            </Row>
            <Row>
              <Col xs={6} className="order-info">Service Required</Col>
              <Col xs={6} className="order-info">{selectedOrder?.serviceName}</Col>
            </Row>
            <Row>
              <Col xs={6} className="order-info">Delivery Person</Col>
              <Col xs={6} className="order-info">{selectedOrder?.deliveryPersonName || '-'}</Col>
            </Row>
            <Row>
              <Col xs={6} className="order-info">Iron Person</Col>
              <Col xs={6} className="order-info">{selectedOrder?.ironPersonName || '-'}</Col>
            </Row>
            <Row>
              <Col xs={6} className="order-info">Pickup Date</Col>
              <Col xs={6} className="order-info">
                {selectedOrder?.pickupDate
                  ? new Date(selectedOrder.pickupDate).toLocaleDateString('en-GB')
                  : '-'}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="order-info">Expected Delivery</Col>
              <Col xs={6} className="order-info">
                {selectedOrder?.expectedDeliveryDate
                  ? new Date(selectedOrder.expectedDeliveryDate).toLocaleDateString('en-GB')
                  : '-'}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="order-info">Urgency</Col>
              <Col xs={6} className="order-info">{selectedOrder?.category}</Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="md" open={attachmentOpen} onClose={() => setAttachmentOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Attachments
            <img style={{ width: '36px', marginLeft: '4%', cursor: 'pointer' }} src={AddNew}
              onClick={handleImageUpload}
            />
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              multiple
              capture={captureMode ? "environment" : undefined}
              onChange={handleAttachmentChange}
              style={{ display: 'none' }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {previewImages?.map((image, index) => (
            <div key={index} style={{ margin: '10px', borderBottom: '1px solid #ddd' }}>
              <img
                src={image}
                alt={`Preview ${index}`}
                style={{
                  width: '60%', height: 'auto',
                  objectFit: 'contain',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  marginBottom: '5%'
                }}
              />
            </div>
          ))}
          {selectedAttachment?.attachment && selectedAttachment.attachment.length > 0 ? (
            <div>
              {selectedAttachment.attachment.map((attachment: any) => (
                <div key={attachment.attachementId} style={{ marginTop: '5%' }}>
                  <img
                    src={attachment.imageData}
                    alt={`Attachment ${attachment.attachementId}`}
                    style={{
                      width: '60%', height: 'auto', marginBottom: '5%', objectFit: 'contain',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p>No attachments available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAttachmentSubmit} disabled={imageLoading}>
            {imageLoading ? 'Submitting...' : 'Submit'}
          </Button>
          <Button onClick={() => setAttachmentOpen(false)} disabled={imageLoading}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="xs" open={imageUpload} onClose={() => setImageUpload(false)}>
        <Modal.Body>
          How would you like to upload an image? Please choose either 'Camera' or 'Gallery.'
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="subtle" onClick={handleCameraClick}>Camera</Button>
          <Button appearance="subtle" onClick={handleGalleryClick}>Gallery</Button>
          <Button appearance="subtle" onClick={() => setImageUpload(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
      <Modal size="xs" open={openOrderHistory} onClose={() => setOpenOrderHistory(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderHistory.length > 0 ? (
            orderHistory.map((order: any, index: any) => (
              <div key={index} className="order-step">
                <div className="status-icon">
                  <i className="fa fa-check-circle"></i> {/* FontAwesome icon */}
                </div>
                <div className="order-information">
                  <div className="status">Status: <b>{order.orderStatus}</b></div>
                  <div className="date"><b>
                    {(order.date)}
                  </b></div>
                  <div className="comments">Comments: <b>{order.comments}</b></div>
                  <div className="processBy">Processed by: <b>{order.processBy}</b></div>
                </div>
              </div>
            ))
          ) : (
            <div>No order history available</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenOrderHistory(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal size="xs" open={isFilterOpen} onClose={() => {
        setIsFilterOpen(false);
        setSelectedApartmentIds([]);
        setSelectedIronPersonIds([]);
        setSelectedDeliveryPersonIds([]);
      }}>
        <Modal.Header closeButton>
          <Modal.Title id="filter-modal">
            Filters
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CheckboxGroup>
            <p>Apartment</p>
            {apartment.map((apt: any) => (
              <div style={{ margin: '1%' }} key={apt.id}>
                <input
                  type="checkbox"
                  checked={apt.checked === true}
                  onChange={() => handleApartmentChange(apt.id)}
                />
                <label style={{ paddingLeft: '5px' }}>{apt.apartmentName}</label>
              </div>
            ))}

            <p>Iron Person</p>
            {ironPersons.map((person: any) => (
              <div style={{ margin: '1%' }} key={person.id}>
                <input
                  type="checkbox"
                  checked={person.checked === true}
                  onChange={() => handleIronPersonChange(person.id)}
                />
                <label style={{ paddingLeft: '5px' }}>{person.name}</label>
              </div>
            ))}

            <p>Delivery Person</p>
            {deliveryPersons.map((person: any) => (
              <div style={{ margin: '1%' }} key={person.id}>
                <input
                  type="checkbox"
                  checked={person.checked === true}
                  onChange={() => handleDeliveryPersonChange(person.id)}
                />
                <label style={{ paddingLeft: '5px' }}>{person.name}</label>
              </div>
            ))}
          </CheckboxGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={() => getLeadsData()}>Apply Filters</Button>
          {/* <Button onClick={() => clearFilter()}>Clear Filters</Button> */}
          <Button
            onClick={() => {
              setIsFilterOpen(false);
              setSelectedApartmentIds([]);
              setSelectedIronPersonIds([]);
              setSelectedDeliveryPersonIds([]);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" role="alertdialog" open={deleteOpen} onClose={() => setDeleteOpen(false)} size="xs">
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <RemindIcon style={{ color: '#ffb300', fontSize: 24, marginBottom: '4%' }} />
          Are you sure you want to delete the Order "{deleteOrder?.orderId}"?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} style={{ marginRight: '2%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }}>
            Delete
          </Button>
          <Button onClick={() => setDeleteOpen(false)} style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddLead;
