import React, { useEffect, useState } from "react";
import "./Header.css";
import menu from "../../assets/header/menu.svg";

interface HeaderProps {
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 908);

  const getName = localStorage.getItem('name');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 908);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="header">
      {isMobile && (
        <button className="mobile-toggle-button" onClick={toggleSidebar}>
          <img src={menu} width={35} height={35} alt="Menu" />
        </button>
      )}
      <div className="left-side">Welcome, {getName}!</div>
      <div className="right-side">
        <div className="search-field">
          {/* Add search input here if needed */}
        </div>
      </div>
    </div>
  );
};

export default Header;
