import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { Button, Modal, TagPicker } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { showError, showSuccess } from "../utils/sweetAlert";
import { callApi } from "../utils/api";
import * as XLSX from 'xlsx';
import './CustomerList.css';
import ExcelCustomerList from './ExcelCustomerList';
import EditIcon from '../../assets/edit.png';
import deleteIcon from "../../assets/delete.png";
import RemindIcon from '@rsuite/icons/legacy/Remind';
import Pagination from 'rsuite/Pagination';

interface LeadData {
  pickupDate: string;
  apartmentName: string;
  flatNo: string;
  mobileNo: string;
  status: string;
  serviceRequired: string;
  deliveryExpectedDate: string;
  assignedDeliveryPerson: string;
  assignedIroningPerson: string;
  category: string;
  comment: string;
  apartmentId: string;
  sno: string;
}

interface CustomerList {
  id: string;
  apartmentName: string;
  flatNo: string;
  mobileNo: string;
  apartmentId: string;
}

const defaultColumns = [
  { key: 'id', label: 'S.No' },
  { key: 'flatName', label: 'Apartment Name' },
  { key: 'flatNo', label: 'Flat No.' },
  { key: 'phoneNumber', label: 'Phone Number' },
  { key: 'actions', label: 'Actions' }
];

const CustomerList = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [formData, setFormData] = useState<LeadData>({
    pickupDate: '',
    apartmentName: '',
    flatNo: '',
    mobileNo: '',
    status: '',
    serviceRequired: '',
    deliveryExpectedDate: '',
    assignedDeliveryPerson: '',
    assignedIroningPerson: '',
    category: '',
    comment: '',
    apartmentId: '',
    sno: ''
  });
  const [users, setUsers] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<LeadData[]>([]);
  const [columnKeys, setColumnKeys] = useState(defaultColumns.map(col => col.key));
  const [suggestions, setSuggestions] = useState([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof LeadData | null, direction: 'asc' | 'desc' } | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CustomerList | null>(null);
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [limit, setLimit] = useState(30);

  const start = (activePage - 1) * limit;
  const end = start + limit;
  const paginatedCustomers = users.slice(start, end);

  const handlePageChange = (page: any) => {
    setActivePage(page);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setActivePage(1);
  };

  useEffect(() => {
    getCustomer();
    getApartment();
  }, [searchTerm]);

  const getCustomer = () => {
    let req = {
      page: '',
      limit: '',
      searchTerm: searchTerm,
    };
    callApi("POST", "customerList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setUsers(response["list"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleRowClick = (lead: any) => {
    navigate(`/layout/customer-info/${lead.id}`, { state: { customer: lead } });
  };

  const handleDelete = () => {
    if (selectedUser) {
      console.log(`Delete user with ID: ${selectedUser.id}`);
      let req = {
        id: selectedUser.id
      };
      callApi("POST", "customerDelete.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          if (response["success"] === true) {
            showSuccess("Success", response.message);
            getCustomer();
            setOpen(false);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleOpen = (user: any) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = () => {
    setLoading(true);
    let req = {
      flatname: formData.apartmentName,
      mobileNo: formData.mobileNo,
      flatNo: formData.flatNo,
    };
    callApi("POST", "customerCreate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setIsModalOpen(false);
          showSuccess("Success!", "Customer created successfully!");
          setFormData({
            pickupDate: '',
            apartmentName: '',
            flatNo: '',
            mobileNo: '',
            status: '',
            serviceRequired: '',
            deliveryExpectedDate: '',
            assignedDeliveryPerson: '',
            assignedIroningPerson: '',
            category: '',
            comment: '',
            apartmentId: '',
            sno: ''
          });
          setLoading(false);
          getCustomer();
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        let data: string | ArrayBuffer = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        let labelsArray: LeadData[] = [];

        workbook.SheetNames.forEach((sheetName: any) => {
          const worksheet = workbook.Sheets[sheetName];
          const sheetData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });

          const extractedData = sheetData.slice(1).map((row: any[]): LeadData | null => {
            if (row[1] && row[2]) {
              return {
                apartmentName: row[1],
                flatNo: row[2],
                mobileNo: row[0],
              } as LeadData;
            }
            return null;
          }).filter((item): item is LeadData => item !== null);
          console.log('extractedData', extractedData)
          labelsArray.push(...extractedData);
          console.log('labelsArray', labelsArray)
        });
        setExcelData(labelsArray);
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleBulkSubmit = () => {
    setBulkLoading(true);
    let req = excelData;
    callApi("POST", "customerUpload.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setIsBulkModalOpen(false);
          showSuccess("Success!", "Customers added successfully!");
          setBulkLoading(false);
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const filteredColumns = defaultColumns.filter(column => columnKeys.includes(column.key));

  const getApartment = () => {
    let req = {
      page: '',
      limit: '',
      searchTerm: searchTerm,
    };
    callApi("POST", "apartmentList.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setSuggestions(response["list"]);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const ascIcon = "↑";
  const descIcon = "↓";

  const handleSort = (key: keyof LeadData) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...users].sort((a: any, b: any) => {
      if (key === "sno") {
        return direction === 'asc' ? a.sno - b.sno : b.sno - a.sno;
      } else {
        if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        return 0;
      }
    });
    setUsers(sortedData);
  };

  const getSortIcon = (key: keyof LeadData) => {
    if (sortConfig?.key === key) {
      return sortConfig.direction === 'asc' ? ascIcon : descIcon;
    }
    return null;
  };

  const handleModal = () => {
    setIsModalOpen(true);
    setFormData({
      pickupDate: '',
      apartmentName: '',
      flatNo: '',
      mobileNo: '',
      status: '',
      serviceRequired: '',
      deliveryExpectedDate: '',
      assignedDeliveryPerson: '',
      assignedIroningPerson: '',
      category: '',
      comment: '',
      apartmentId: '',
      sno: ''
    })
  }

  const handleEdit = (lead: any) => {
    setFormData({
      pickupDate: lead.pickupDate || '',
      apartmentName: lead.apartmentId || '',
      flatNo: lead.flatNo || '',
      mobileNo: lead.mobileNo || '',
      status: lead.status || '',
      serviceRequired: lead.serviceRequired || '',
      deliveryExpectedDate: lead.deliveryExpectedDate || '',
      assignedDeliveryPerson: lead.assignedDeliveryPerson || '',
      assignedIroningPerson: lead.assignedIroningPerson || '',
      category: lead.category || '',
      comment: lead.comment || '',
      apartmentId: lead.apartmentId || '',
      sno: ''
    });
    setSelectedUser(lead)
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = () => {
    setEditLoading(true);
    let req = {
      flatname: formData.apartmentName,
      mobileNo: formData.mobileNo,
      flatNo: formData.flatNo,
      id: selectedUser?.id
    };
    callApi("POST", "customerUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setIsEditModalOpen(false);
          showSuccess("Success!", "Customer Updated successfully!");
          setFormData({
            pickupDate: '',
            apartmentName: '',
            flatNo: '',
            mobileNo: '',
            status: '',
            serviceRequired: '',
            deliveryExpectedDate: '',
            assignedDeliveryPerson: '',
            assignedIroningPerson: '',
            category: '',
            comment: '',
            apartmentId: '',
            sno: ''
          });
          getCustomer();
          setEditLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };


  return (
    <>
      <div className="custom-table">
        <h5 style={{ width: '92%', marginLeft: '4%', marginTop: '2%', height: '0px' }}>Customer Management</h5>
        <div className='mobile-button' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: '1%', display: 'flex', justifyContent: 'flex-end' }}>
            <ExcelCustomerList data={users} status='customer-management' />
          </div>
          <Button style={{ marginRight: '1%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} onClick={handleModal}>
            Add Customer
          </Button>
          <Button style={{ marginRight: '4%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }} onClick={() => setIsBulkModalOpen(true)}>
            Bulk Upload
          </Button>
        </div>
        <TagPicker
          data={defaultColumns}
          labelKey="label"
          valueKey="key"
          value={columnKeys}
          onChange={setColumnKeys}
          cleanable={false}
          style={{ width: '70%', marginLeft: '4%', marginTop: '2%' }}
        />
        <Form.Control
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '30%', marginLeft: '4%', marginTop: '20px', marginBottom: '20px' }}
        />
        <Table bordered responsive style={{ width: '92%', margin: '0% 4% 1%' }}>
          <thead>
            <tr>
              {filteredColumns?.map(column => (
                <th key={column.key} onClick={() => handleSort(column.key as keyof LeadData)} style={{ cursor: 'pointer' }}>
                  {column.label} {getSortIcon(column.key as keyof LeadData)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedCustomers.length === 0 ? (
              <tr>
                <td colSpan={filteredColumns.length} style={{ textAlign: 'center' }}>
                  No Customers added yet
                </td>
              </tr>
            ) : (
              paginatedCustomers.map((lead, index) => (
                <tr key={index}>
                  {columnKeys.includes('id') && <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{index + 1}</td>}
                  {columnKeys.includes('flatName') && <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.apartmentName}</td>}
                  {columnKeys.includes('flatNo') && <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.flatNo}</td>}
                  {columnKeys.includes('phoneNumber') && <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.mobileNo}</td>}
                  {columnKeys.includes('orders') && <td onClick={() => handleRowClick(lead)} style={{ cursor: 'pointer' }}>{lead.orders || '-'}</td>}
                  {columnKeys.includes('actions') && (
                    <td>
                      <img onClick={() => handleEdit(lead)} style={{ width: '16px', marginRight: '10px', cursor: 'pointer' }} src={EditIcon} alt="edit-icon" />
                      <img onClick={() => handleOpen(lead)} style={{ width: '16px', cursor: 'pointer' }} src={deleteIcon} alt="delete-icon" />
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <Pagination
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          size="md"
          total={users.length}
          limit={limit}
          limitOptions={[30, 50]}
          activePage={activePage}
          onChangePage={handlePageChange}
          onChangeLimit={handleLimitChange}
          style={{ marginLeft: '4%', marginRight: '4%' }}
        />
      </div>
      <Modal size="lg" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Create Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: "36px" }}>
          <div className="lead-form">
            <div className="leadformfields">
              <label htmlFor="apartmentName">Apartment Name<span style={{ color: 'red' }}>*</span></label>
              <select
                className="addleadinput"
                id="apartmentName"
                value={formData.apartmentName}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Apartment</option>
                {suggestions.map((apartment: any) => (
                  <option key={apartment.id} value={apartment.id}>
                    {apartment.apartmentName}
                  </option>
                ))}
              </select>
            </div>
            <div className="leadformfields">
              <label htmlFor="flatNo">Flat No.<span style={{ color: 'red' }}>*</span></label>
              <input
                className="addleadinput"
                type="text"
                id="flatNo"
                value={formData.flatNo}
                onChange={handleInputChange}
                required />
            </div>
            <div className="leadformfields">
              <label htmlFor="mobileNo">Phone Number<span style={{ color: 'red' }}>*</span></label>
              <input
                className="addleadinput"
                type="tel"
                id="mobileNo"
                value={formData.mobileNo}
                onChange={handleInputChange}
                maxLength={10}
                minLength={10}
                required />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsModalOpen(false)} appearance="subtle" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} appearance="primary" disabled={loading}>
            {loading ? 'Creating Customer...' : 'Create Customer'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginLeft: "36px" }}>
          <div className="lead-form">
            <div className="leadformfields">
              <label htmlFor="apartmentName">Apartment Name<span style={{ color: 'red' }}>*</span></label>
              <select
                className="addleadinput"
                id="apartmentName"
                value={formData.apartmentName}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Apartment</option>
                {suggestions.map((apartment: any) => (
                  <option key={apartment.id} value={apartment.id}>
                    {apartment.apartmentName}
                  </option>
                ))}
              </select>
            </div>
            <div className="leadformfields">
              <label htmlFor="flatNo">Flat No.<span style={{ color: 'red' }}>*</span></label>
              <input
                className="addleadinput"
                type="text"
                id="flatNo"
                value={formData.flatNo}
                onChange={handleInputChange}
                required />
            </div>
            <div className="leadformfields">
              <label htmlFor="mobileNo">Phone Number<span style={{ color: 'red' }}>*</span></label>
              <input
                className="addleadinput"
                type="tel"
                id="mobileNo"
                value={formData.mobileNo}
                onChange={handleInputChange}
                maxLength={10}
                minLength={10}
                required />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsEditModalOpen(false)} appearance="subtle" disabled={editLoading}>
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} appearance="primary" disabled={editLoading}>
            {editLoading ? 'Updating Customer...' : 'Update Customer'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="md" open={isBulkModalOpen} onClose={() => setIsBulkModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Bulk Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button className="sample">
            <a
              href="/Customer_list.xlsx"
              download="Sample_Customer_List_Format.xlsx"
              className="atag"
            >
              Download Sample Customer List Format
            </a>
          </Button>
          <input style={{ marginLeft: '4%' }} type="file" accept=".xls,.xlsx,.csv" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setIsBulkModalOpen(false)} appearance="subtle" disabled={bulkLoading}>
            Cancel
          </Button>
          <Button onClick={handleBulkSubmit} disabled={bulkLoading} appearance="primary" style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }}>
            {bulkLoading ? 'Uploading File...' : 'Upload File'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal backdrop="static" role="alertdialog" open={open} onClose={() => setOpen(false)} size="xs">
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <RemindIcon style={{ color: '#ffb300', fontSize: 24, marginBottom: '4%' }} />
          Are you sure you want to delete the customer "{selectedUser?.apartmentName}-{selectedUser?.flatNo}-{selectedUser?.mobileNo}"?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} style={{ marginRight: '2%', background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }}>
            Delete
          </Button>
          <Button onClick={() => setOpen(false)} style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff' }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerList;
