import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";

const PaymentUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    paymentType: '',
    deliveryStatus: ''
  });
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (user) {
      getPayment();
    }
    getDeliveryStatus();
    console.log(formData)
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getPayment = () => {
    let req = {
      id: user
    };
    callApi("POST", "paymentMethodGetId.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          setFormData({
            id: response.id,
            paymentType: response.paymentType,
            deliveryStatus: response.deliveryStatus
          });
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getDeliveryStatus = () => {
    callApi("POST", "statusList.php", '')
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setStatus(response.data);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addPayment = () => {
    setLoading(true);
    const trimmedPaymentName = formData.paymentType.trim();
    if (trimmedPaymentName) {
      let req = {
        paymentType: trimmedPaymentName,
        deliveryStatus: formData.deliveryStatus
      };
      console.log("addPayment-->");

      callApi("POST", "paymentMethodInsert.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          console.log("response", response);
          if (response["success"] === true) {
            showSuccess("Success!", "Payment method created successfully!");
            setTimeout(() => {
              navigate("/layout/payment-management");
            }, 1000);
            setLoading(false);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      showError(
        "Failed", "Empty Space Not Allowed");
    }
  }

  const updatePayment = () => {
    setLoading(true);
    let req = {
      id: formData.id,
      paymentType: formData.paymentType,
      deliveryStatus: formData.deliveryStatus
    };
    callApi("POST", "paymentMethodUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Payment method updated successfully!");
          setTimeout(() => {
            navigate("/layout/payment-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (user) {
      updatePayment();
    } else {
      addPayment();
      console.log(formData)
    }
  };

  return (
    <div style={{ margin: '2% 4% 4%' }}>
      <h5>{user ? 'Edit Payment Method' : 'Add New Payment Method'}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formPaymentMethod" style={{ marginTop: '1%' }}>
          <Form.Label>Payment Method <span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="paymentType"
            value={formData.paymentType}
            onChange={handleChange}
            required
            minLength={2}
            maxLength={50}
          />
        </Form.Group>
        <Form.Group controlId="formDeliveryStatus" style={{ marginTop: '4%' }}>
          <Form.Label>Payment Status <span style={{ color: 'red' }}>*</span></Form.Label>
          <select
            className="form-control"
            name="deliveryStatus"
            value={formData.deliveryStatus}
            onChange={handleChange}
            required
          >
            <option value="">Select Payment Status</option>
            {status
              .filter((status: any) => status.id === '5' || status.id === '6')
              .map((status: any) => (
                <option key={status.id} value={status.id}>
                  {status.status}
                </option>
              ))}
          </select>
        </Form.Group>
        <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', marginTop: '10%' }} type="submit" disabled={loading}>
          {user ? 'Update Payment Method' : 'Add Payment Method'}
        </Button>
      </Form>
    </div>
  );
};

export default PaymentUpdate;
