import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Sidebar.css";
import ArrowRight from "../../assets/Arrow-right.svg";
import ArrowDown from "../../assets/Arrow-down.svg";
import Logout from "../../assets/sidebar/logout.svg";
import Logo from "../../assets/logo.png";
import CloseIcon from '@rsuite/icons/Close';
import { Modal, Button, ButtonToolbar } from 'rsuite';
import Dashboardblack from "../../assets/sideicons/dashboard b.svg";
import Dashboardwhite from "../../assets/sideicons/dashboard w.svg";
import Settings from "../../assets/Settings.svg";
import tobepickedupw from "../../assets/sideicons/To be Picked up w.svg";
import tobepickedupb from "../../assets/sideicons/To be Picked up b.svg";
import pickedupw from "../../assets/sideicons/picked up w.svg";
import pickedupb from "../../assets/sideicons/picked up b.svg";
import processingw from "../../assets/sideicons/processing w.svg";
import processingb from "../../assets/sideicons/processing b.svg";
import readydeliveryw from "../../assets/sideicons/Ready for delivery w.svg";
import readydeliveryb from "../../assets/sideicons/Ready for delivery b.svg";
import paymentpendingw from "../../assets/sideicons/payment pending w.svg";
import paymentpendingb from "../../assets/sideicons/payment pending b.svg";
import deliveredw from "../../assets/sideicons/Delivered w.svg";
import deliveredb from "../../assets/sideicons/Delivered b.svg";
import customerlistw from "../../assets/sideicons/Customer list w.svg";
import customerlistb from "../../assets/sideicons/Customer list b.svg";
import usermanagementw from "../../assets/sideicons/User management w.svg";
import usermanagementb from "../../assets/sideicons/User management b.svg";
import apartmangementw from "../../assets/sideicons/Apartment w.svg";
import apartmangementb from "../../assets/sideicons/Apartment b.svg";
import servicew from "../../assets/sideicons/Service W.svg";
import serviceb from "../../assets/sideicons/Service b.svg";
import paymentmw from "../../assets/sideicons/payment management w.svg";
import paymentmb from "../../assets/sideicons/payment management b.svg";
import productmw from "../../assets/sideicons/product management w.svg";
import productmb from "../../assets/sideicons/product management b.svg";


interface SidebarProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isSidebarOpen, toggleSidebar }) => {
  const [activeMenu, setActiveMenu] = useState('Dashboard');
  const [role, setRole] = useState("");
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [masterClick, setMasterClick] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  }
  const handleLogout = () => {
    localStorage.removeItem('authkey');
    localStorage.removeItem('role');
    localStorage.removeItem('name');
    window.location.href = "/";
  }

  useEffect(() => {
    const userRole = localStorage.getItem('role');
    if (userRole) {
      setRole(userRole);
    }
  }, []);

  const handleMenuClick = (menu: string) => {
    setActiveMenu(menu);
    toggleSidebar();
    console.log("activeMenu", menu);
  };


  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div ref={sidebarRef} className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
      {/* <div className={`sidebar open`}> */}
      <div className="sidebar-header">
        <div className="header-content">
          <div className="sidebar-heading">
            <Link to="/layout/dashboard" style={{ width: "200px", height: "46px" }}>
              <img
                src={Logo}
                alt="Logo"
                style={{ width: "100%" }}
                onClick={() => handleMenuClick("Dashboard")}
              />
            </Link>
            <CloseIcon onClick={toggleSidebar} style={{ width: '30px', float: 'inline-end', cursor: 'pointer' }} />
          </div>
        </div>
      </div>

      <ul className="sidebar-menu">
        <div>
          {/* Dashboard */}
          <li>
            <NavLink
              className="menu-button"
              style={{
                background:
                  activeMenu === "Dashboard"
                    ? "#9291AC"
                    : "#E6E6E6",
                color: activeMenu === "Dashboard" ? "#fff" : "black",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Dashboard")}
            >
              <img
                src={activeMenu === "Dashboard" ? Dashboardwhite : Dashboardblack}
                alt="dashboard"
              />{" "}
              <div className="sidebar-title">
                <span>Dashboard</span>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "To be picked up"
                  ? "#9291AC"
                  : "#E6E6E6",
                color: activeMenu === "To be picked up" ? "#fff" : "black",
                padding: "12px 8px",
              }}
              to="/layout/orders/1"
              onClick={() => handleMenuClick("To be picked up")}
            >
              <img
                src={activeMenu === "To be picked up" ? tobepickedupw : tobepickedupb}
                alt="To be picked up" />{" "}
              <div className="sidebar-title">
                <span>To be Picked Up</span>
              </div>
            </NavLink>
          </li><li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Picked Up"
                  ? "#9291AC"
                  : "#E6E6E6",
                color: activeMenu === "Picked Up" ? "#fff" : "black",
                padding: "12px 8px",
              }}
              to="/layout/orders/2"
              onClick={() => handleMenuClick("Picked Up")}
            >
              <img
                src={activeMenu === "Picked Up" ? pickedupw : pickedupb}
                alt="Picked Up" />{" "}
              <div className="sidebar-title">
                <span>Picked Up</span>
              </div>
            </NavLink>
          </li><li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Processing"
                  ? "#9291AC"
                  : "#E6E6E6",
                color: activeMenu === "Processing" ? "#fff" : "black",
                padding: "12px 8px",
              }}
              to="/layout/orders/3"
              onClick={() => handleMenuClick("Processing")}
            >
              <img
                src={activeMenu === "Processing" ? processingw : processingb}
                alt="Processing" />{" "}
              <div className="sidebar-title">
                <span>Processing</span>
              </div>
            </NavLink>
          </li><li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Ready for Delivery"
                  ? "#9291AC"
                  : "#E6E6E6",
                color: activeMenu === "Ready for Delivery" ? "#fff" : "black",
                padding: "12px 8px",
              }}
              to="/layout/orders/4"
              onClick={() => handleMenuClick("Ready for Delivery")}
            >
              <img
                src={activeMenu === "Ready for Delivery" ? readydeliveryw : readydeliveryb}
                alt="Ready for Delivery" />{" "}
              <div className="sidebar-title">
                <span>Ready for Delivery</span>
              </div>
            </NavLink>
          </li>
          {(role === '1' || role === '3') && (
            <>
              <li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "Payment Pending"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "Payment Pending" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  to="/layout/orders/5"
                  onClick={() => handleMenuClick("Payment Pending")}
                >
                  <img
                    src={activeMenu === "Payment Pending" ? paymentpendingw : paymentpendingb}
                    alt="Payment Pending" />{" "}
                  <div className="sidebar-title">
                    <span>Payment Pending</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "Delivered"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "Delivered" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  to="/layout/orders/6"
                  onClick={() => handleMenuClick("Delivered")}
                >
                  <img
                    src={activeMenu === "Delivered" ? deliveredw : deliveredb}
                    alt="Delivered" />{" "}
                  <div className="sidebar-title">
                    <span>Delivered</span>
                  </div>
                </NavLink>
              </li>
            </>
          )}
          {role === '1' && (
            <li>
              <a
                className="menu-button"
                style={{
                  color: "black",
                  padding: "12px 8px",
                  background: "#E6E6E6",
                }}
                onClick={() => setMasterClick(!masterClick)}
              >
                <img
                  src={Settings}
                  alt="Settings" />{" "}
                <div className="sidebar-title">
                  <span>Settings</span>
                  <img
                    src={masterClick === true ? ArrowDown : ArrowRight}
                    alt="Settings" />
                </div>
              </a>
            </li>
          )}
          {(role === '1' && masterClick) && (
            <>
              <li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "Customer List"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "Customer List" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  to="/layout/customer-list"
                  onClick={() => handleMenuClick("Customer List")}
                >
                  <img
                    src={activeMenu === "Customer List" ? customerlistw : customerlistb}
                    alt="Customer List" />{" "}
                  <div className="sidebar-title">
                    <span>Customer Management</span>
                  </div>
                </NavLink>
              </li><li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "User Management"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "User Management" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  onClick={() => handleMenuClick("User Management")}
                  to="/layout/user-management"
                >
                  <img
                    src={activeMenu === "User Management" ? usermanagementw : usermanagementb}
                    alt="User Management" />{" "}
                  <div className="sidebar-title">
                    <span>User Management</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "Apartment Management"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "Apartment Management" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  onClick={() => handleMenuClick("Apartment Management")}
                  to="/layout/apartment-management"
                >
                  <img
                    src={activeMenu === "Apartment Management" ? apartmangementw : apartmangementb}
                    alt="Apartment Management" />{" "}
                  <div className="sidebar-title">
                    <span>Apartment Management</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "Service Management"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "Service Management" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  onClick={() => handleMenuClick("Service Management")}
                  to="/layout/service-management"
                >
                  <img
                    src={activeMenu === "Service Management" ? servicew : serviceb}
                    alt="Service Management" />{" "}
                  <div className="sidebar-title">
                    <span>Service Management</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "Payment Management"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "Payment Management" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  onClick={() => handleMenuClick("Payment Management")}
                  to="/layout/payment-management"
                >
                  <img
                    src={activeMenu === "Payment Management" ? paymentmw : paymentmb}
                    alt="Payment Management" />{" "}
                  <div className="sidebar-title">
                    <span>Payment Management</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu-button"
                  style={{
                    background: activeMenu === "Product Management"
                      ? "#9291AC"
                      : "#E6E6E6",
                    color: activeMenu === "Product Management" ? "#fff" : "black",
                    padding: "12px 8px",
                  }}
                  onClick={() => handleMenuClick("Product Management")}
                  to="/layout/product-management"
                >
                  <img
                    src={activeMenu === "Product Management" ? productmw : productmb}
                    alt="Product Management" />{" "}
                  <div className="sidebar-title">
                    <span>Product Management</span>
                  </div>
                </NavLink>
              </li>
            </>)}
        </div>

        <div className="sidebar-menu">
          <li style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <div
              className="menu-button"
              style={{
                background: "#E6E6E6",
                color: "#ec536c",
                padding: "8px",
              }}
              onClick={handleOpen}
            >
              <img src={Logout} style={{ width: '32px', height: '32px' }} alt="Logout" />
              <div className="sidebar-title">
                <span style={{ color: '#ec536c' }}>Logout</span>
              </div>
            </div>
          </li>
        </div>
        <Modal open={open} onClose={handleClose}>
          <Modal.Body>
            <div>Are you sure you want to Logout?</div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleLogout} appearance="primary">
              Yes
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </ul>
    </div>
  );
};

export default Sidebar;