import React from "react";
import ExcelJS from "exceljs";
import { Button } from "rsuite";

interface LeadData {
  pickupDate: string;
  apartmentName: string;
  flatNo: string;
  mobileNo: string;
  serviceName: string;
  expectedDeliveryDate: string;
  deliveryPersonName: string;
  ironPersonName: string;
  category: string;
  orderId: string;
}

const ExcelFiles = ({ data, status }: { data: LeadData[]; status: string }) => {
  const exportExcelFile = async () => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return;
    }
    console.log("Data:", data);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.columns = [
      { header: 'S.No', key: 's_no', width: 10 },
      { header: 'Order ID', key: 'orderId', width: 20 },
      { header: 'Apartment Name', key: 'apartmentName', width: 30 },
      { header: 'Flat No', key: 'flatNo', width: 15 },
      { header: 'Mobile No', key: 'mobileNo', width: 20 },
      { header: 'Service', key: 'serviceName', width: 20 },
      { header: 'Pickup Date', key: 'pickupDate', width: 20 },
      { header: 'Expected Delivery Date', key: 'expectedDeliveryDate', width: 25 },
      { header: 'Delivery Person Name', key: 'deliveryPersonName', width: 30 },
      { header: 'Iron Person Name', key: 'ironPersonName', width: 30 },
      { header: 'Urgency', key: 'category', width: 30 },
    ];
    worksheet.getRow(1).eachCell((cell: any) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '9293AC' },
      };
      cell.font = { bold: true, size: 14 };
    });
    const flattenedData = data.map((item, index) => ({
      s_no: index + 1,
      orderId: item.orderId,
      apartmentName: item.apartmentName,
      flatNo: item.flatNo,
      mobileNo: item.mobileNo,
      serviceName: item.serviceName,
      pickupDate: item.pickupDate,
      expectedDeliveryDate: item.expectedDeliveryDate,
      deliveryPersonName: item.deliveryPersonName,
      ironPersonName: item.ironPersonName,
      category: item.category,
    }));
    flattenedData.forEach((item) => {
      const row = worksheet.addRow(item);
      row.eachCell((cell: any) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      });
    });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${status}-${formattedDate}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button style={{background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', color: '#fff'}} onClick={exportExcelFile}>
      Excel
    </Button>
  );
};

export default ExcelFiles;
