import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import Select from 'react-select'

const ApartmentUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ironPersons, setIronPersons] = useState([]);
  const [deliveryPersons, setDeliveryPersons] = useState([]);
  const user = location.state?.user;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    apartmentName: '',
    ironPersonName: '',
    ironPersonId: '',
    deliveryPersonName: '',
    deliveryPersonId: '',
  });

  useEffect(() => {
    getIronPersons();
    getDeliveryPersons();
    if (user) {
      getApartment();
    }
    console.log(formData)
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getApartment = () => {
    let req = {
      id: user
    };
    callApi("POST", "apartmentGetId.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          setFormData({
            id: response.id,
            apartmentName: response.apartmentName,
            ironPersonName: response.ironPersonName,
            ironPersonId: response.ironPersonId,
            deliveryPersonName: response.deliveryPersonName,
            deliveryPersonId: response.deliveryPersonId,
          });
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getIronPersons = () => {
    let req = {
      roleId: 2,
    }
    callApi("POST", "userRoleGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setIronPersons(response.list);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDeliveryPersons = () => {
    let req = {
      roleId: 3,
    }
    callApi("POST", "userRoleGet.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          setDeliveryPersons(response.list);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addApartment = () => {
    setLoading(true);
    const trimmedApartName = formData.apartmentName.trim();
    if (trimmedApartName) {
    let req = {
      apartmentName: trimmedApartName,
      ironPersonId: formData.ironPersonId,
      deliveryPersonId: formData.deliveryPersonId,
    };
    callApi("POST", "apartmentInsert.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Apartment created successfully!");
          setTimeout(() => {
            navigate("/layout/apartment-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
    }else {
      showError(
          "Failed", "Empty Space Not Allowed");
          formData.apartmentName="";
  }
  }

  const updateApartment = () => {
    setLoading(true);
    let req = {
      id: formData.id,
      apartmentName: formData.apartmentName,
      ironPersonId: formData.ironPersonId,
      deliveryPersonId: formData.deliveryPersonId,
    };
    callApi("POST", "apartmentUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Apartment updated successfully!");
          setTimeout(() => {
            navigate("/layout/apartment-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (user) {
      updateApartment();
    } else {
      addApartment();
      console.log(formData)
    }
  };

  return (
    <div style={{ margin: '2% 4% 4%' }}>
      <h5>{user ? 'Edit Apartment' : 'Add New Apartment'}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" style={{ marginTop: '1%' }}>
          <Form.Label>Apartment Name<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="apartmentName"
            value={formData.apartmentName}
            onChange={handleChange}
            required
            minLength={2}
            maxLength={100}
          />
        </Form.Group>
        <Form.Group controlId="formIronPerson" style={{ marginTop: '4%' }}>
          <Form.Label>Assigned Iron Person<span style={{ color: 'red' }}>*</span></Form.Label>
          <select
            className="form-control"
            name="ironPersonId"
            value={formData.ironPersonId} 
            onChange={handleChange}
            required
          >
            <option value="">Select Iron Person</option>
            {ironPersons.map((person: any) => (
              <option key={person.id} value={person.id}>
                {person.name}
              </option>
            ))}
          </select>
        </Form.Group>
        <Form.Group controlId="formDeliveryPerson" style={{ marginTop: '4%' }}>
          <Form.Label>Assigned Delivery Person<span style={{ color: 'red' }}>*</span></Form.Label>
          <select
            className="form-control"
            name="deliveryPersonId"
            value={formData.deliveryPersonId} 
            onChange={handleChange}
            required
          >
            <option value="">Select Delivery Person</option>
            {deliveryPersons.map((person: any) => (
              <option key={person.id} value={person.id}>
                {person.name}
              </option>
            ))}
          </select>
        </Form.Group>
        <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', marginTop: '10%' }} type="submit" disabled={loading}>
          {user ? 'Update Apartment' : 'Add Apartment'}
        </Button>
      </Form>
    </div>
  );
};

export default ApartmentUpdate;
