import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import Select from 'react-select'

const UserUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const user = location.state?.user;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    password: '',
    roles: [],
    number: '',
    address: ''
  });

  useEffect(() => {
    getRoles();
    if (user) {
      getEmployee();
    }
    console.log(formData)
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoleChange = (selectedOptions: any) => {
    const selectedRoles = selectedOptions.map((option: any) => ({
      id: option.value,
      role: option.label
    }));
    setFormData({ ...formData, roles: selectedRoles });
  };

  const getEmployee = () => {
    console.log("user--->",user);
    
    let req = {
      id: user.id
    };
    
    callApi("POST", "employeeGetId.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          setFormData({
            id: response.id,
            name: response.name,
            email: response.email,
            password: response.password,
            roles: response?.role ? response.role.map((role: any) => ({ id: role.id, role: role.role })) : [],
            number: response.mobileNo,
            address: response.address || '', 
          });
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getRoles = () => {
    callApi("GET", "roleGet.php")
      .then((res) => res.data)
      .then((response: any) => {
        if (response["success"] === true) {
          const formattedRoles = response["list"].map((role: any) => ({
            value: role.id,
            label: role.role,
          }));
          setRoles(formattedRoles);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addEmployee = () => {
    setLoading(true);
    const trimmedName = formData.name.trim();
    const trimmedEmail = formData.email.trim();
    const trimmedPassword = formData.password.trim();
    const trimmedNumber = formData.number.trim();
    const trimmedAddress = formData.address.trim();

    if (trimmedName && trimmedEmail && trimmedPassword && trimmedNumber && trimmedAddress ) {
    let req = {
      name: trimmedName,
      email: trimmedEmail,
      password: trimmedPassword,
      mobileNo: trimmedNumber,
      address: trimmedAddress,
      role: formData.roles,
    };
    callApi("POST", "employeeInsert.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "User created successfully!");
          setTimeout(() => {
            navigate("/layout/user-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      }); 
    }else {
      showError(
          "Failed", "Empty Space Not Allowed");
  }

  }

  const updateEmployee = () => {
    setLoading(true);
    let req = {
      id: formData.id,
      name: formData.name,
      email: formData.email,
      password: formData.password,
      mobileNo: formData.number,
      address: formData.address,
      role: formData.roles,
    };
    callApi("POST", "employeeUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "User updated successfully!");
          setTimeout(() => {
            navigate("/layout/user-management");
          }, 1000);
          setLoading(false);
        } else {
          showError("Failed", response.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (user) {
      updateEmployee();
    } else {
      addEmployee();
    }
  };

  return (
    <div style={{ margin: '2% 4% 4%' }}>
      <h5>{user ? 'Edit User' : 'Add New User'}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" style={{ marginTop: '1%' }}>
          <Form.Label>Name<span style={{color: 'red'}}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            minLength={2}
            maxLength={50}
          />
        </Form.Group>
        <Form.Group controlId="formRoles" style={{ marginTop: '1%' }}>
          <Form.Label>Roles<span style={{color: 'red'}}>*</span></Form.Label>
          <Select
            isMulti
            name="roles"
            onChange={handleRoleChange}
            options={roles}
            value={formData.roles.map((role: any) => ({ value: role.id, label: role.role }))}
            className="basic-multi-select"
            classNamePrefix="select"
            required
            
          />
        </Form.Group>
        <Form.Group controlId="formNumber" style={{ marginTop: '1%' }}>
          <Form.Label>Phone Number<span style={{color: 'red'}}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="number"
            value={formData.number}
            onChange={handleChange}
            maxLength={10}
            minLength={10}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail" style={{ marginTop: '1%' }}>
          <Form.Label>Email<span style={{color: 'red'}}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            minLength={11}
            maxLength={60}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPassword" style={{ marginTop: '1%' }}>
          <Form.Label>Password<span style={{color: 'red'}}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            minLength={8}
            maxLength={15}
          />
        </Form.Group>
        <Form.Group controlId="formAddress" style={{ marginTop: '1%', marginBottom: '2%' }}>
          <Form.Label>Address<span style={{color: 'red'}}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            minLength={4}
            maxLength={150}
            required
          />
        </Form.Group>
        <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)' }} type="submit" disabled={loading}>
          {user ? 'Update User' : 'Add User'}
        </Button>
      </Form>
    </div>
  );
};

export default UserUpdate;
