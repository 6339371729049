import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import { Button, Modal } from 'rsuite';
import OrderHistory from '../../assets/history.png';

interface Order {
  orderid: string;
  productName: string;
  quantity: number;
  price: number;
}

interface Customer {
  customerId: string;
  apartmentName: string;
  pickupDate: string;
  expectedDeliveryDate: string;
  flatNo: string;
  mobileNo: string;
  orderStatus: string | null;
  amount: number;
  amountStatus: string;
  service: string;
  orders: Order[] | null;
  orderId: string;
}

const CustomerInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [openOrderHistory, setOpenOrderHistory] = useState(false);

  useEffect(() => {
    const getCustomerDetails = () => {
      const req = { id: id };
      callApi('POST', 'customerOrders.php', req)
        .then((res) => res.data)
        .then((response: any) => {
          if (response && response.length > 0) {
            setCustomers(response);
          } else {
            console.error('Error fetching customer data:', response);
          }
        })
        .catch((err) => {
          console.error('API call failed:', err);
        });
    };

    if (id) {
      getCustomerDetails();
    }
  }, [id]);

  const getOrderHistory = (id: any) => {
    let req = {
      id: id
    };
    callApi("POST", "orderHistory.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          setOrderHistory(response.data);
          console.log("order history", response)
        } else {
          showError("Failed", response.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (customers.length === 0) {
    return <div>No customer information available.</div>;
  }

  const handleOrderHistory = (lead: any) => {
    getOrderHistory(lead.id);
    setOpenOrderHistory(true);
  }

  return (
    <><div className="custom-table">
      {customers.map((customer, customerIndex) => (
        <div key={customer.customerId + customerIndex} style={{ marginBottom: '2rem' }}>
          <div style={{ width: '92%', marginLeft: '4%', marginTop: '2%' }}>
            <h5 style={{ marginBottom: '1%' }}>Order {customer.orderId} - {customer.apartmentName}({customer.flatNo}) </h5>
          </div>
          <Table bordered responsive style={{ width: '92%', margin: '0% 4%' }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Product Name</th>
                <th>Pickup Date</th>
                <th>Expected Delivery Date</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Service</th>
                <th>Order Status</th>
                <th>Order History</th>
              </tr>
            </thead>
            <tbody>
              {customer.orders && customer.orders.length > 0 ? (
                customer.orders.map((order, index) => (
                  <tr key={order.orderid || index}>
                    <td>{index + 1}</td>
                    <td>{order.productName}</td>
                    <td>{customer.pickupDate ? new Date(customer.pickupDate).toLocaleDateString() : "N/A"}</td>
                    <td>{customer.expectedDeliveryDate ? new Date(customer.expectedDeliveryDate).toLocaleDateString() : "N/A"}</td>
                    <td>{order.quantity}</td>
                    <td>{order.price}</td>
                    <td>{customer.service}</td>
                    <td>{customer.orderStatus || "N/A"}</td>
                    <td onClick={() => handleOrderHistory(customer)} style={{ cursor: 'pointer' }}><img style={{ width: '25px' }} src={OrderHistory} alt="icon" /></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>No Order Items added yet</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      ))}
    </div><Modal size="xs" open={openOrderHistory} onClose={() => setOpenOrderHistory(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Order History
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderHistory.length > 0 ? (
            orderHistory.map((order: any, index: any) => (
              <div key={index} className="order-step">
                <div className="status-icon">
                  <i className="fa fa-check-circle"></i> {/* FontAwesome icon */}
                </div>
                <div className="order-information">
                  <div className="status">Status: <b>{order.orderStatus}</b></div>
                  <div className="date"><b>
                    {(order.date)}
                  </b></div>
                  <div className="comments">Comments: <b>{order.comments || '-'}</b></div>
                  <div className="processBy">Processed by: <b>{order.processBy}</b></div>
                </div>
              </div>
            ))
          ) : (
            <div>No order history available</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenOrderHistory(false)}>Close</Button>
        </Modal.Footer>
      </Modal></>
  );
};

export default CustomerInfo;
