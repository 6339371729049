import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";

const ServiceUpdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    service: '',
  });

  useEffect(() => {
    if (user) {
      getService();
    }
    console.log(formData)
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getService = () => {
    let req = {
      id: user
    };
    callApi("POST", "serviceGetId.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response) {
          setFormData({
            id: response.id,
            service: response.service,
          });
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addService = () => {
    setLoading(true);
    const trimmedServiceName = formData.service.trim();
    if (trimmedServiceName) {
      let req = {
        service: trimmedServiceName,
      };
      callApi("POST", "serviceInsert.php", req)
        .then((res) => res.data)
        .then((response: any) => {
          console.log("response", response);
          if (response["success"] === true) {
            showSuccess("Success!", "Service created successfully!");
            setTimeout(() => {
              navigate("/layout/service-management");
            }, 1000);
            setLoading(false);
          } else {
            showError("Failed", response.error);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      showError(
        "Failed", "Empty Space Not Allowed");
    }

  }

  const updateService = () => {
    setLoading(true);
    let req = {
      id: formData.id,
      service: formData.service,
    };
    callApi("POST", "serviceUpdate.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Service updated successfully!");
          setTimeout(() => {
            navigate("/layout/service-management");
          }, 1000);
        } else {
          showError("Failed", response.error);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (user) {
      updateService();
    } else {
      addService();
      console.log(formData)
    }
  };

  return (
    <div style={{ margin: '2% 4% 4%' }}>
      <h5>{user ? 'Edit Service' : 'Add New Service'}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formService" style={{ marginTop: '1%' }}>
          <Form.Label>Service Name<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            name="service"
            value={formData.service}
            onChange={handleChange}
            minLength={2}
            maxLength={100}
            required
          />
        </Form.Group>
        <Button style={{ background: 'rgb(146, 145, 172)', border: 'rgb(146, 145, 172)', marginTop: '10%' }} type="submit" disabled={loading}>
          {user ? 'Update Service' : 'Add Service'}
        </Button>
      </Form>
    </div>
  );
};

export default ServiceUpdate;
