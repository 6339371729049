import React, { Component, useEffect, useState } from "react";
import "./Login.css";
import { CardBody, Col, Row } from "react-bootstrap";
import { callApi } from "../utils/api";
import { showError, showSuccess } from "../utils/sweetAlert";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  const navigate = useNavigate();

  const [phoneNumber, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");

  const onchange = (e: any) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      setEmailError("");
    } else if (name === "password") {
      setPassword(value);
      setPasswordError("");
    }
  };

  const handleClick = () => {
    console.log("surya");

    let emailError = "";
    let passwordError = "";

    if (!phoneNumber) {
      emailError = "Mobile Number is required";
    }

    if (!password) {
      passwordError = "Password is required";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return;
    }

    let req = {
      phoneNumber: phoneNumber,
      password: password,
    };
    callApi("POST", "login.php", req)
      .then((res) => res.data)
      .then((response: any) => {
        console.log("response", response);
        if (response["success"] === true) {
          console.log("response", response.response.auth_key, response.response.user);
          localStorage.setItem("authkey", response.response.auth_key);
          localStorage.setItem("name", response.response.user.name);
          localStorage.setItem("role", response.response.user.role);
          showSuccess("Success!", "Login Successfully!");
          setTimeout(() => {
            navigate("layout/dashboard");
          }, 1000);
        } else {
          showError("Failed", "Email & Password are not matched!");
        }

      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <main className="overflow">
      <div>
        <Row style={{ height: "100vh" }}>
          <Col lg="3" md="3"></Col>
          <Col lg="6" md="6" className="p-4 centercol alignitemcenter"
            style={{ padding: "0 !important" }}>
            <CardBody>
              <div className="loginform">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img style={{ width: '230px' }} src={logo} alt="" className="logo" />
                </div>
                <p className="place text-center">Enter your login details</p>
                <div className="paddingleft" style={{ marginTop: '20px' }}>
                  <div className="labeldiv pt-2 pb-2">
                    <label className="label">Mobile Number *</label>
                    <div className="form-login"  >
                      <input
                        type="email"
                        className="form-control bg"
                        name="email"
                        required
                        value={phoneNumber}
                        onChange={onchange}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={10}
                        minLength={10}
                      />
                    </div>
                    <div className="text-danger">{emailError}</div>
                  </div>
                  <div className="labeldiv pt-2 pb-2">
                    <label className="label">Password *</label>
                    <div className="form-login"  >
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control bg"
                        id="password"
                        name="password"
                        required
                        value={password}
                        onChange={onchange}
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        className="icon eyeicon"
                        style={{cursor: 'pointer'}}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <div className="text-danger">{passwordError}</div>
                  </div>
                  <div className="d-grid" style={{ marginTop: '20px' }}>
                    <button
                      className="form-control btn btn-primary subbutton"
                      onClick={handleClick}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </CardBody>
          </Col>
          <Col lg="3" md="3"></Col>
        </Row>
      </div>
    </main>
  );
}
